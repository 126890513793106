export default {
  methods: {
    setInsuranceDataFromBackendData(data) {
      const insuranceForm = {
        relationship: data.relationship,
        plan_type: data.plan_type,
        payor: data.payor,
        policy_id: data.policy_id,
        insurance_code: data.insurance_code,
        gender: data.gender,
        date_of_birth: data.date_of_birth,
        policy_plan: data.policy_plan,
        group_or_plan: data.group_or_plan,
        group: data.group,
        patient_policy: data.patient_policy,
        address_line_1: data.address_line_1,
        address_line_2: data.address_line_2,
        city: data.city,
        state: data.state,
        zip: data.zip,
        first_name: data.first_name,
        last_name: data.last_name,
        middle_name: data.middle_name,
        insurance_carrier_code: data.insurance_carrier_code,
      }

      return insuranceForm
    },
  },
}
