<template>
  <section>
    <div class="case-creation__body pb-0">
      <div class="vue-form">
        <el-form :model="patientInformation" ref="ruleForm">
          <el-row :gutter="15">
            <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">
                    First Name
                    <span class="star">*</span>
                  </label>

                  <el-input
                    v-model="patientInformation.first_name"
                    placeholder="Enter First Name"
                    class="el-input"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError errorName="patient_info.first_name"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">Middle Name</label>
                  <el-input
                    v-model="patientInformation.middle_name"
                    placeholder="Enter Middle Name"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError errorName="patient_info.middle_name"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">
                    Last Name
                    <span class="star">*</span>
                  </label>
                  <el-input
                    v-model="patientInformation.last_name"
                    placeholder="Enter Last Name"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError errorName="patient_info.last_name"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">
                    Gender
                    <span class="star">*</span>
                  </label>
                  <div class="gender-select">
                    <el-select
                      v-model="patientInformation.gender"
                      placeholder="Gender"
                      filterable
                      default-first-option
                      :reserve-keyword="false"
                      clearable
                      @change="handlePregnancyData"
                      :disabled="!isBillingUser"
                    >
                      <el-option
                        v-for="(gender, index) in genderOptions"
                        :key="index"
                        :label="gender"
                        :value="gender"
                      ></el-option>
                    </el-select>
                  </div>
                  <p class="err">
                    <FormError errorName="patient_info.gender"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="date-of-birth case-creation__input">
                <dobVersion
                  v-if="showPatientDob"
                  :dobData="patientInformation"
                  :validataionErrors="getErrors"
                  :errorKey="'patient_info.date_of_birth'"
                  ref="dobField"
                ></dobVersion>
              </div>
            </el-col>

            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">EMR</label>
                  <el-input
                    v-model="patientInformation.emr"
                    placeholder="Enter EMR"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError errorName="patient_info.emr"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input mobile-icon">
                <el-form-item>
                  <label for="text">
                    Mobile Number
                    <span class="star">*</span>
                  </label>
                  <el-input
                    v-model="patientInformation.home_phone"
                    placeholder="Enter Mobile Number"
                    maxlength="10"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError errorName="patient_info.home_phone"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input mobile-icon">
                <el-form-item>
                  <label for="text">Phone Number</label>
                  <el-input
                    v-model="patientInformation.work_phone"
                    placeholder="Enter Phone Number"
                    maxlength="10"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError errorName="patient_info.work_phone"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="7" :lg="7" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">Email</label>
                  <el-input
                    v-model="patientInformation.email"
                    placeholder="Enter Email"
                    class="el-input email-lower"
                    type="email"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError errorName="patient_info.email"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="7" :lg="7" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">
                    Address Line 1
                    <span class="star">*</span>
                  </label>
                  <el-input
                    v-model="patientInformation.address_line_1"
                    placeholder="Enter Address Line 1"
                    class="el-input"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="patient_info.address_line_1"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="6" :lg="6" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">Address Line 2</label>

                  <el-input
                    v-model="patientInformation.address_line_2"
                    placeholder="Enter Address Line 2"
                    class="el-input"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError
                      errorName="patient_info.address_line_2"
                    ></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>

            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">
                    Zip
                    <span class="star">*</span>
                  </label>
                  <el-input
                    v-model="patientInformation.zip"
                    placeholder="Enter Zip"
                    maxlength="5"
                    @input="getStateCity"
                    :disabled="!isBillingUser"
                  ></el-input>

                  <p class="err">
                    <FormError errorName="patient_info.zip"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="date-of-birth case-creation__input">
                <el-form-item>
                  <label for="text">County</label>
                  <el-input
                    placeholder="Enter County"
                    v-model="patientInformation.county"
                    :disabled="!isBillingUser"
                  ></el-input>
                  <p class="err">
                    <FormError errorName="patient_info.county"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="4" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="date-of-birth case-creation__input">
                <el-form-item>
                  <state
                    v-show="showDob"
                    :stateData="patientInformation"
                    :disableField="!isBillingUser"
                  ></state>
                  <p class="err">
                    <FormError errorName="patient_info.state"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="4" :md="8" :sm="12" :xs="12">
              <div class="date-of-birth case-creation__input">
                <el-form-item>
                  <city v-if="showDob" :cityData="patientInformation" :disableField="!isBillingUser"></city>
                  <p class="err">
                    <FormError errorName="patient_info.city"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="3" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">&nbsp;</label>
                  <div class="verify-address-btn">
                    <el-button
                      :disabled="hasAddress || !isBillingUser"
                      :loading="verifyButtonLoading"
                      @click="verifyAddress"
                      >Verify Address</el-button
                    >
                  </div>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="4" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">Race</label>
                  <div class="gender-select">
                    <el-select
                      v-model="patientInformation.race"
                      placeholder="Select Race"
                      filterable
                      default-first-option
                      :reserve-keyword="false"
                      clearable
                      :disabled="!isBillingUser"
                    >
                      <el-option
                        v-for="(race, index) in raceOptions"
                        :key="index"
                        :label="race"
                        :value="race"
                      ></el-option>
                    </el-select>
                  </div>
                  <p class="err">
                    <FormError errorName="patient_info.race"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col :xl="4" :lg="3" :md="8" :sm="12" :xs="12">
              <div class="case-creation__input">
                <el-form-item>
                  <label for="text">Ethnicity</label>
                  <div class="gender-select">
                    <el-select
                      v-model="patientInformation.ethnicity"
                      placeholder="Select Ethnicity"
                      filterable
                      default-first-option
                      :reserve-keyword="false"
                      clearable
                      :disabled="!isBillingUser"
                    >
                      <el-option
                        v-for="(ethnicity, index) in ethnicityOptions"
                        :key="index"
                        :label="ethnicity"
                        :value="ethnicity"
                      ></el-option>
                    </el-select>
                  </div>
                  <p class="err">
                    <FormError errorName="patient_info.ethnicity"></FormError>
                  </p>
                </el-form-item>
              </div>
            </el-col>
            <el-col
              :xl="4"
              :lg="3"
              :md="8"
              :sm="12"
              :xs="12"
              v-if="patientInformation.gender == 'FEMALE'"
            >
              <div class="pregancy-field">
                <label for="">Pregnancy </label>
                <el-form-item class="text mb-0">
                  <el-checkbox
                    class="el-checkbox-group"
                    v-model="patientInformation.pregnant"
                    size="large"
                    :disabled="!isBillingUser"
                  />
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <div class="case-creation__footer">
      <div class="patient-details">
        <el-button
          class="look-up-button btn"
          type="text"
          @click="openLookUpDialog()"
          :disabled="!isBillingUser"
          >Lookup</el-button
        >
      </div>
    </div>
    <LookUpPopup
      v-if="lookupDialogVisible"
      :lookupSearchInfo="patientInformation"
      @closeLookUpPopup="handleCloseLookUpPopup"
      @selectPatientInfo="updatePatientInfo"
    ></LookUpPopup>
    <div class="default-modal" v-if="this.addressData">
      <el-dialog v-model="verifyAddressPopup">
        <div class="dialog-header">
          <span class="header-part"
            >The address you entered was found but more information is
            needed</span
          >
          <el-button
            size="medium"
            class="el-white"
            type="info"
            plain
            @click="verifyAddressPopup = false"
          >
            <img src="@/assets/images/icons/cases/close-btn.svg" alt="icon" />
          </el-button>
        </div>
        <div class="address-data-fields">
          <ul class="address-lists">
            <li>
              <p class="name">Address</p>
              <span class="value">: {{ this.addressData.Address1 }}</span>
            </li>
            <li>
              <p class="name">Zip</p>
              <span class="value">: {{ this.addressData.Zip5 }}</span>
            </li>
            <li>
              <p class="name">State</p>
              <span class="value">: {{ this.addressData.State }}</span>
            </li>
            <li>
              <p class="name">City</p>
              <span class="value">: {{ this.addressData.City }}</span>
            </li>
          </ul>
          <div class="dialog-footer">
            <el-button class="blue-save-button" @click="fillAddressData"
              >Update Address</el-button
            >
          </div>
        </div>
      </el-dialog>
    </div>
  </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { mapGetters } from "vuex";
import caseConstants from "@/config/constants/caseConstants";
import moment from "moment";

const state = defineAsyncComponent(() =>
  import("@/components/misc/BillingState")
);
const city = defineAsyncComponent(() =>
  import("@/components/misc/BillingCity")
);
const dobVersion = defineAsyncComponent(() =>
  import("@/components/misc/DobNewFormat")
);

const FormError = defineAsyncComponent(() =>
  import("@/components/misc/FormError")
);
const LookUpPopup = defineAsyncComponent(() =>
  import("@/components/cases/LookUpPopup")
);
export default {
  props: ["patientInfo"],
  data() {
    return {
      verifyAddressPopup: false,
      addressData: {},
      verifyButtonLoading: false,
      ethnicityOptions: caseConstants.ethnicityOptions,
      raceOptions: caseConstants.raceOptions,

      //patient information
      patientInformation: {
        first_name: "",
        middle_name: "",
        last_name: "",
        gender: "",
        date_of_birth: "",
        emr: "",
        pms_or_pws: "",
        home_phone: "",
        work_phone: "",
        email: "",
        address_line_1: "",
        address_line_2: "",
        county: "",
        city: "",
        state: "",
        zip: "",
        race: "",
        ethnicity: "",
        pregnant: false,
      },
      genderOptions: caseConstants.genderOptions,
      show: false,
      showDob: false,
      showPatientDob: false,
      lookupDialogVisible: false,
      loading: false,
      patientInsurance: {},
    };
  },
  components: {
    state,
    city,
    dobVersion,
    FormError,
    LookUpPopup,
  },
  computed: {
    ...mapGetters("cases", [
      "getCaseInformation",
      "getStateAndCity",
      "getVerifyAddress",
      "getAddress",
    ]),
    ...mapGetters("errors", ["getErrors"]),
    hasAddress() {
      if (
        this.patientInformation.address_line_1 &&
        this.patientInformation.zip &&
        this.patientInformation.state &&
        this.patientInformation.city
      ) {
        return false;
      }
      return true;
    },
  },
  mounted() {
    this.loadInitData();
  },
  methods: {
    async handlePregnancyData() {
      if (this.patientInformation.gender != "FEMALE") {
        this.patientInformation.pregnant = false;
      }
    },
    async loadInitData() {
      this.show = true;
      this.showPatientState = true;
      this.showPatientDob = true;
      this.showDob = true;
      if (this.$route.params.case_id) {
        this.caseId = this.$route.params.case_id;
        this.checkCaseInfo();
      }
    },
    async verifyAddress() {
      try {
        this.verifyButtonLoading = true;
        let params = {
          address1: this.patientInformation.address_line_1,
          address2: this.patientInformation.address_line_2,
          zip: this.patientInformation.zip,
          city: this.patientInformation.city,
          state: this.patientInformation.state,
        };
        await this.$store.dispatch("cases/fetchAddressVerification", params);
        if (
          this.getVerifyAddress &&
          this.getVerifyAddress.misMatch &&
          this.getVerifyAddress.misMatch == true
        ) {
          this.addressData = this.getVerifyAddress.data;
          this.verifyAddressPopup = true;
        } else if (this.getVerifyAddress) {
          this.$notify.success({
            title: "Success",
            message: "Address verified successfully",
          });
        } else {
          if (!this.getVerifyAddress) {
            this.$notify.error({
              title: "Error",
              message: "Address verification failed",
            });
          }
        }
        this.verifyButtonLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async fillAddressData() {
      if (this.addressData) {
        this.verifyAddressPopup = false;
        this.patientInformation.address_line_1 = this.addressData.Address1;
        this.patientInformation.zip = this.addressData.Zip5;
        this.patientInformation.state = this.addressData.State;
        this.patientInformation.city = this.addressData.City;
        this.$notify.success({
          title: "Success",
          message: "Verified address updated successfully",
        });
      }
    },
    checkCaseInfo() {
      if (this.getCaseInformation) {
        if (this.getCaseInformation.patient_info) {
          this.patientInformation = {
            ...this.getCaseInformation.patient_info,
            emr: this.getCaseInformation.emr_reference_id || null,
          };
        }
      }
    },

    checkPatientDob() {
      if (this.patientInformation) {
        return this.$refs.dobField.checkValidDOBDate();
      }
    },
    formatPatientInfo() {
      return this.parsePatientInfo(this.patientInformation);
    },

    async getStateCity() {
      try {
        let params = {
          zip: this.patientInformation.zip,
        };
        await this.$store.dispatch("cases/getStateCity", params);
        if (this.getStateAndCity) {
          this.patientInformation.state = this.getStateAndCity.state;
          this.patientInformation.city = this.getStateAndCity.city;
        } else {
          if (!this.getStateAndCity && params.zip.length == 5) {
            this.$message({
              message: "No State & City Found",
              type: "warning",
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async getAutoCompletePatientsAddress(queryString, cb) {
      if (queryString && queryString.length > 1) {
        let query = queryString;
        await this.$store.dispatch("cases/getAddress", query);
        const patientsData = [];
        (this.getAddress.results || []).forEach((el) => {
          patientsData.push({
            value: el.formatted_address,
            ...el,
          });
        });

        cb(patientsData || []);
      } else {
        cb([]);
      }
    },
    handleSelect(data) {
      let address = data.formatted_address;
      address = address.split(",");
      this.patientInformation.address_line_1 = address[0];
      if (address[2] && address[2].length) {
        let zipCode = address[2];
        zipCode = zipCode.split(" ");
        this.patientInformation.zip = zipCode[2];
        this.getStateCity();
      }
    },
    clearFields() {
      this.patientInformation.address_line_1 = "";
      this.patientInformation.zip = "";
      this.patientInformation.state = "";
      this.patientInformation.city = "";
    },
    parsePatientInfo(patientInfo) {
      let params = { ...patientInfo };
      if (!params.work_phone) delete params.work_phone;
      if (!params.middle_name) delete params.middle_name;
      if (!params.home_phone) delete params.home_phone;
      if (!params.address_line_1) delete params.address_line_1;
      if (!params.address_line_2) delete params.address_line_2;
      if (!params.race) delete params.race;
      if (!params.ethnicity) delete params.ethnicity;
      if (!params.pregnant) delete params.pregnant;
      if (params.email) {
        params.email = params.email.toLowerCase();
      }

      return params;
    },
    openLookUpDialog() {
      const dobField = this.$refs["dobField"];

      if (
        !this.patientInformation.date_of_birth &&
        (dobField.day || dobField.year || dobField.month)
      ) {
        this.$message({
          message: "Please select valid DOB!",
          type: "warning",
        });
        this.loading = false;
        this.tableVisible = false;

        return null;
      }

      const info = this.patientInformation;
      if (
        Object.keys(
          info.first_name ||
            info.last_name ||
            info.middle_name ||
            info.gender ||
            info.date_of_birth ||
            info.emr
        ).length < 1
      ) {
        this.$message({
          message: "Please select an option to search!",
          type: "warning",
        });

        return null;
      }

      if (this.patientInformation.date_of_birth) {
        var input = this.patientInformation.date_of_birth;
        if (input.length) {
          var date = moment(input);
          if (!date.isValid()) {
            this.$message({
              message: "Invalid DOB!",
              type: "warning",
            });

            return null;
          }
        }
      }
      this.lookupDialogVisible = false;
      setTimeout(() => {
        this.lookupDialogVisible = true;
      }, 100);
    },
    handleCloseLookUpPopup() {
      this.lookupDialogVisible = false;
    },
    updatePatientInfo(selectedPatientData) {
      this.patientInsurance = selectedPatientData;
      this.showPatientDob = false;
      this.$emit("updateInsurance", this.patientInsurance);

      this.showPatientState = false;
      this.patientInformation = {
        ...this.patientInformation,
        ...selectedPatientData.patient_info,
      };
      this.lookupDialogVisible = false;
      this.showPatientState = true;

      this.showPatientDob = true;
    },
  },
  watch: {
    "patientInformation.last_name": {
      immediate: true,
      handler: function () {
        if (this.patientInformation.last_name) {
          this.$emit("updateInsurance", this.patientInsurance);
        }
      },
    },
  },
};
</script>
